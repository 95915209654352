<template>
  <div>
    <b-form @submit.stop.prevent="submitData">
      <div class="row no-gutters mx-n1">
        <!-- Date Picker -->
        <div class="col-lg-6 col-12 px-1">
          <b-form-group
            id="input-group-date"
            label="Dari Tgl:"
            label-for="input-date"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  locale="id"
                  placeholder="Tanggal Mulai"
                  id="input-date"
                  v-model="form.start_date"
                  button-only
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="input-date"
                v-model="form.start_date"
                type="text"
                placeholder="YYYY-MM-DD"
                @input="formaterDate"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
          <small class="text-danger">{{ error.start_date }}</small>
        </div>

        <!-- Date Picker -->
        <div class="col-lg-6 col-12 px-1">
          <b-form-group
            id="input-group-date"
            label="Tanggal Selesai:"
            label-for="input-date-end"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  locale="id"
                  placeholder="Tanggal"
                  v-model="form.end_date"
                  button-only
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="input-date-end"
                v-model="form.end_date"
                type="text"
                placeholder="YYYY-MM-DD"
                @input="formaterDateEnd"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
          <small class="text-danger">{{ error.end_date }}</small>
        </div>
      </div>

      <!-- Name input -->
      <b-form-group id="input-group-name" label="Nama:" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama Kupon"
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <div class="row">
        <div class="col-md-4">
          <!-- Input Jenis -->
          <b-form-group
            id="input-group-status"
            label="Jenis Potongan:"
            label-for="input-status"
          >
            <b-form-select
              id="input-status"
              v-model="form.is_percent"
              :options="type"
              @change="setType(form.is_percent)"
            ></b-form-select>
            <small class="text-danger">{{ error.is_percent }}</small>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            id="input-group-name"
            label="Besar Potongan:"
            label-for="input-name"
          >
            <b-input-group :prepend="type_value" class="">
              <b-form-input
                id="input-amount"
                v-model="formatedAmount"
                inputmode="numeric"
                placeholder="Besar Potongan"
              ></b-form-input>
            </b-input-group>
            <small class="text-danger">{{ error.amount }}</small>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group id="input-group-name">
            <label for="input-max-amount"
              >Maksimal Potongan : <em class="text-muted">opsional</em></label
            >
            <b-input-group prepend="Rp" class="">
              <b-form-input
                id="input-max-amount"
                v-model="formatedMaxAmount"
                inputmode="numeric"
                placeholder="Maksimal Potongan"
                :disabled="form.is_percent == 0"
              ></b-form-input>
              <small class="text-danger">{{ error.max_amount }}</small>
            </b-input-group>
          </b-form-group>
        </div>
      </div>

      <hr />

      <!-- Description input -->
      <b-form-group id="input-group-description">
        <label for="input-description"
          >Deskripsi : <em class="text-muted">opsional</em></label
        >
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          placeholder="Deksripsi"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/coupons')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // type coupon
      type: [
        {
          text: "Nominal (Rp)",
          value: 0,
        },
        {
          text: "Persen (%)",
          value: 1,
        },
      ],
      type_value: "Rp",
      // Error
      error: {
        name: "",
        is_percent: "",
        start_date: "",
        end_date: "",
        amount: "",
        max_amount: "",
        description: "",
      },
    };
  },

  computed: {
    formatedAmount: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.amount = newValue.toLocaleString("id-ID");
        } else {
          this.form.amount = 0;
        }
      },
      get() {
        return this.form.amount;
      },
    },
    formatedMaxAmount: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.max_amount = newValue.toLocaleString("id-ID");
        } else {
          this.form.max_amount = 0;
        }
      },
      get() {
        return this.form.max_amount;
      },
    },
  },

  methods: {
    submitData() {
      if (this.form.is_percent == 1) {
        if (this.form.amount > 100) {
          Swal.fire(
            "Perhatian",
            "Besaran Potongan Kupon tidak boleh lebih dari 100%",
            "warning"
          );
        } else {
          this.formOnsubmit();
        }
      } else {
        this.formOnsubmit();
      }
    },

    async formOnsubmit() {
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/masters/coupons");
      }
    },

    setType(data) {
      if (data == 0) {
        this.type_value = "Rp";
      } else {
        this.type_value = "%";
      }
    },
    formaterDate(val) {
      if (val.length == 4) {
        this.form.start_date = this.form.start_date + "-";
      }
      if (val.length == 7) {
        this.form.start_date = this.form.start_date + "-";
      }
    },

    formaterDateEnd(val) {
      if (val.length == 4) {
        this.form.end_date = this.form.end_date + "-";
      }
      if (val.length == 7) {
        this.form.end_date = this.form.end_date + "-";
      }
    },
  },

  mounted() {
    if (this.form.is_percent == 1) {
      this.type_value = "%"
      this.form.max_amount = parseInt(this.form.max_amount).toLocaleString('id-ID')
    }else{
      this.type_value = "Rp"
    }
  }
};
</script>

<style>
</style>